/* eslint-env node, browser */

/**
 * Enviroment configurations, used in both browser and Node JS.
 */
 /* istanbul ignore next */
 (function(exports) {
  'use strict';

  /**
   * Get cookie by name.
   * http://stackoverflow.com/questions/10730362/get-cookie-by-name
   */
  function getCookie(name) {
    if (typeof document !== 'undefined') {
      var value = '; ' + document.cookie;
      var parts = value.split('; ' + name + '=');
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
    }
  };

  /**
   * Resolve env.
   * location: an environment type (string) or an object that contains protocol, hostname, and port.
   * For example, getEnv('development') or getEnv({ protocol: 'https:', hostname: 'spa.feidev.com' })
   */
  function getEnv(location) {

    var gatewayBaseUrlProd = 'gateway.financialengines.com'
    var gatewayBaseUrlDevTest = 'gateway.feitest.com'

    // type-to-location map
    // CX-17082 add the key 'awshostname' to the map.

    var locations = {
      'local': { protocol: 'http:', hostname: 'localhost', port: '3000', awsgateway: gatewayBaseUrlDevTest, envname: 'localhost' },
      'development': { protocol: 'http:', hostname: 'localhost', port: '3000', awsgateway: gatewayBaseUrlDevTest, envname: 'localhost' },
      'test': { protocol: 'https:', hostname: 'mainline.feidev.com', awshostname: 'gateway.feitest.com/apiproxy', awsgateway: gatewayBaseUrlDevTest, envname: 'mainline' },
      'acceptance': { protocol: 'https:', hostname: 'spa.feidev.com', awsgateway: gatewayBaseUrlDevTest, envname: 'spa' },
      'acceptanceAWS': { protocol: 'https:', hostname: 'spa-aws.feitest.io', awsgateway: gatewayBaseUrlDevTest, envname: 'spa-aws' },
      'partner': { protocol: 'https:', hostname: 'www.feitest.com', awshostname: 'gateway.feitest.com/advisor', awsgateway: gatewayBaseUrlDevTest, envname: 'feitest' },
      'emgtest': { protocol: 'https:', hostname: 'emg.feitest.com', awshostname: 'gateway.feitest.com/apiproxy', awsgateway: gatewayBaseUrlDevTest, envname: 'emgtest'  },
      'plantest': { protocol: 'https:', hostname: 'plantest.financialengines.com', awshostname: 'gateway.feitest.com/apiproxy', awsgateway: gatewayBaseUrlDevTest, envname: 'plantest' },
      'demo': { protocol: 'https:', hostname: 'demo.financialengines.com', awshostname: 'gateway.feitest.com/apiproxy', awsgateway: gatewayBaseUrlDevTest, envname: 'demo' },
      'repro': { protocol: 'https:', hostname: 'repro.feitest.io', awshostname: 'gateway.feitest.com/apiproxy', awsgateway: gatewayBaseUrlDevTest, envname: 'repro'  },
      'production': { protocol: 'https:', hostname: 'www.financialengines.com', awshostname: 'gateway.financialengines.com/advisor', awsgateway: gatewayBaseUrlProd, envname:  'production' },
      // TO-590: Help with SPA bring up in Virginia DR - add new environment mapping
      'virginiadr': { protocol: 'https:', hostname: 'www-dr.financialengines.com', awshostname: 'gateway-dr.financialengines.com/advisor', awsgateway: 'gateway-dr.financialengines.com', envname:  'dr' },
      'acceptanceRelease': { protocol: 'https:', hostname: 'sparelease.feitest.io', awshostname: 'gateway.feitest.com/apiproxy', awsgateway: gatewayBaseUrlDevTest, envname: 'sparelease' }
    };

    // type-to-config map
    var configs = {
      'local': { debug: true, proxyTarget: 'http://localhost:3000', includeAppName: false },
      'development': { debug: false, includeAppName: true },
      'acceptance': { debug: false, includeAppName: true },
      'acceptanceAWS': { debug: false, includeAppName: true },
      'test': { debug: false, includeAppName: true },
      'partner': { debug: false, includeAppName: true },
      'emgtest': { debug: false, includeAppName: true },
      'plantest': { debug: false, includeAppName: true },
      'demo': { debug: false, includeAppName: true },
      'repro': { debug: false, includeAppName: true },
      'production': { debug: false, includeAppName: true },
      // TO-590: Help with SPA bring up in Virginia DR - add new environment mapping
      'virginiadr': { debug: false, includeAppName: true },
      'acceptanceRelease': { debug: false, includeAppName: true }
    };


    //API gateway enabled environments - mainline, feitest, repro, production
    var apiGatewayEnabledEnvironments = {
      'test' : true,
      'partner' : true,
      'emgtest' : true,
      'repro' : true,
      'production' : true,
      'virginiadr': true
    };

    // FE-env-type to AWS-env map
    var awsEnv = {
      'local': 'dev',
      'development': 'dev',
      'test': 'dev',
      'acceptance': 'dev',
      'acceptanceAWS': 'test',
      'partner': 'test',
      'emgtest': 'test',
      'demo': 'test',
      'repro': 'test',
      'plantest': 'production',
      'production': 'production',
      'virginiadr': 'production',
      'acceptanceRelease': 'test'
    }

    if (typeof location === 'string') {
      // location is an env type string. Use it to look up the location object
      location = locations[location];
    }

    var env = {};
    env.projectName = ['legal-docs-app'][0];
    env.debug = false;

    // Env type
    for (var envType in locations) {
      var loc = locations[envType];
      if (loc && loc.hostname === location.hostname) {
        env.type = envType;
        env.awsEnvType = awsEnv[envType];
        break;
      }
    }
    if (!env.type) {
      // Default to local if no env type
      env.type = 'local';
    }

    // Copy configs to env
    var envConfig = configs[env.type];
    for (var prop in envConfig) {
      var val = envConfig[prop];
      if (typeof val !== 'undefined') {
        env[prop] = val;
      }
    }

    // Resolve URLs
    env.baseUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/';
    env.appBaseUrl = (env.includeAppName === false) ? env.baseUrl : env.baseUrl + 'app/' + env.projectName + '/';

    env.envName = locations[env.type].envname ? locations[env.type].envname : '';

    if(apiGatewayEnabledEnvironments[env.type] !== null && apiGatewayEnabledEnvironments[env.type] !== undefined &&
      apiGatewayEnabledEnvironments[env.type] === true){
      //Get the root domain from hostname. For instance, mainline.feidev.com will return feidev.com
      var host = location.hostname;
      host = host.split('.');
      var rootDomain = host.pop();
      rootDomain = host.pop() + '.' + rootDomain;

      env.restBaseUrl = env.baseUrl + 'api/';
      // CX-17082 if authtype has value of 'aws', rewrite rest base URL.
      var authTypeValue = getCookie('authType');
      if (typeof document !== 'undefined' && authTypeValue && authTypeValue === 'aws') {
        env.restBaseUrl = location.protocol + '//' + locations[env.type].awshostname + '/api/';
      }
      env.restEnvBaseUrl = env.baseUrl;
      env.microserviceBaseUrl = location.protocol + '//' + locations[env.type].awsgateway + '/';
    } else {
      env.restBaseUrl = env.baseUrl + 'api/';
      env.restEnvBaseUrl = env.baseUrl;
      env.microserviceBaseUrl =  env.baseUrl;
    }

    // Resolve URL for AWS S3 video buckets
    env.videoBaseUrl = 'https://s3-us-west-1.amazonaws.com/images.financialengines.com/video/';

    return env;
  } // /function getEnv


  //
  // Populate env
  //

  var env;
  if (typeof window !== 'undefined') {
    // Resolve env immediately if window.location is available
    env = getEnv(window.location);
  }
  else {
    // By default, use development env
    env = getEnv('development');
  }


  //
  // Get data layer from cookie if this script is running inside a browser
  //

  if (typeof document !== 'undefined') {

    // /**
    //  * Get cookie by name.
    //  * http://stackoverflow.com/questions/10730362/get-cookie-by-name
    //  */
    // var getCookie = function(name) {
    //   var value = '; ' + document.cookie;
    //   var parts = value.split('; ' + name + '=');
    //   if (parts.length === 2) {
    //     return parts.pop().split(';').shift();
    //   }
    // };

    // Data analysis data layer
    var daVars;
    var daVarsStr = getCookie('daVars');
    if (daVarsStr) {
      try {
        daVars = JSON.parse(decodeURIComponent(daVarsStr));
        daVars.namespace = env.projectName;
        daVars.pageName = env.projectName;
      }
      catch (err) {
        throw new Error('Invalid daVars');
      }
    }
    else {
      daVars = {
        namespace: env.projectName,
        pageName: env.projectName,
        envType: 'USER_' + (env.type === 'production' ? 'PROD' : 'TEST'),
        userType: 'USER'
      };
    }

    env.da = daVars;
  }


  //
  // Exports
  //

  exports.env = env;
  exports.getEnv = getEnv;

})(typeof exports === 'undefined' ? (this.fe = this.fe || {}) : exports);
